<template>
    <div class="sld_chat_history">
        <div class="top_header">
            <p class="header_title">{{L['聊天记录']}}</p>
        </div>

        <div class="main_acc_top">
            <template v-if="identity=='seller'">
                <el-scrollbar id="el-scrollbar_top">
                    <div :class="{acc_top_item:true,csOn:index==cacIdx}" v-for="(item,index) in sellerStoreList"
                        :key="index" @click="changeUnderSeller(index,item)" :title="item.vendorName">
                        {{item.vendorName?item.vendorName:item.storeName}}</div>
                    <span></span>
                </el-scrollbar>
            </template>


            <template v-else-if="identity=='admin'&&isSuper==true">
                <div :class="{acc_top_item:true,csOn:0==cacIdx}" @click="changeUnderAdmin(0,item)">平台</div>
                <div :class="{acc_top_item:true,csOn:1==cacIdx}" @click="changeUnderAdmin(1,item)">商家</div>
            </template>


        </div>

        <div class="middle_bar" v-if="showUnderAdmin">
            <div class="acc_name flex_row_start_center">
                <span style="margin: 0 14px;">商家:</span>
                <el-select v-model="storeChoose" placeholder="请选择商家" @change="adminStoChange" filterable>
                    <el-option v-for="(item,index) in adminStoreList" :key="index" :label="item.storeName"
                        :value="item.storeId">
                    </el-option>
                </el-select>
            </div>
        </div>

        <div class="middle_bar">

            <div class="acc_name" v-if="isStoreAdmin==1">
                <span :title="vendorName">{{vendorName}}</span><span>{{L['聊天记录']}}</span>
            </div>

            <div class="acc_name flex_row_start_center" v-if="identity=='admin'&&adminVenList.length&&isSuper==true">
                <span style="margin: 0 14px;">客服:</span>
                <el-select v-model="vendorChoose" placeholder="请选择客服" @change="adminVenChange"
                    :disabled="adminVenDisable">
                    <el-option v-for="(item,index) in adminVenList" :key="index" :label="item.vendorName"
                        :value="item.vendorId">
                    </el-option>
                </el-select>
            </div>


            <div class="chat_content">
                <span>{{L['消息内容']}}：</span>
                <el-input type="text" :placeholder="L['请输入消息内容']" v-model="msgFilter" @clear="clearContent('content')"
                    clearable>
                </el-input>
            </div>

            <div class="chat_date">
                <span>{{L['聊天日期']}}：</span>
                <el-date-picker v-model="dateRange" type="daterange" range-separator="-" :start-placeholder="L['开始日期']"
                    :end-placeholder="L['结束日期']" :disabled-date="disabledDate" clearable>
                </el-date-picker>
            </div>

            <button class="query" @click="query">{{L['查询']}}</button>
        </div>

        <div class="chat_con" :style="`height:${clientHeight-233}px`">


            <div class="chat_list">

                <div class="search">
                    <div class="search_wrap">
                        <i class="el-icon-search"></i>
                        <input type="text" id="store_search" :placeholder="L['搜索最近联系人']" v-model="searchMember"
                            @input="didSearch = false">
                        <i class="el-icon-close" @click="clearInput" v-show="searchMember"></i>
                        <div @click="searchMemberList" class="button">{{L['搜索']}}</div>
                    </div>
                </div>

                <div :style="`height:${clientHeight-300}px`">
                    <el-scrollbar>
                        <!-- 左侧聊天会员列表start -->
                        <div class="left_list" v-infinite-scroll="loadlList" :infinite-scroll-distance="10"
                            :infinite-scroll-immediate="false">
                            <div class="left_list_item" v-for="(item,index) in chatMemberList.list" :key="index"
                                @click="chatInit(item)"
                                :style="{'background-color':item.memberId == GlobalMemberId&&item.storeId==GlobalStoreId?'#F5F5F5':'#fff'}">
                                <img :src="item.memberAvatar" alt="">
                                <p class="item_name">{{item.memberName}}</p>

                            </div>
                            <loadingState v-if="loadState == 'first_loading'||chatMemberList.list.length > 0"
                                :state='loadState' />
                        </div>
                        <div class="empty_data_left" v-if="!chatMemberList.list.length>0">
                            <img src="@/assets/goods/empty_data.png" alt="">
                            <p>{{L['暂无数据～']}}</p>
                        </div>
                        <!-- 左侧聊天会员列表end -->
                    </el-scrollbar>
                </div>
            </div>


            <div class="wind_chat">
                <chatWindow ref="chatWindow"></chatWindow>
            </div>
        </div>
    </div>
</template>

<script>
    import chatWindow from '@/components/chatWindow'
    import { onMounted, getCurrentInstance, reactive, ref, watch, computed, nextTick } from 'vue'
    import loadingState from '@/components/loadingState'
    // import { useStore } from 'vuex'
    import { ElMessage } from 'element-plus'
    export default {
        name: 'chatHistory',
        components: {
            loadingState,
            chatWindow
        },
        setup() {
            const GlobalMemberId = ref('')//全局会员Id
            const GlobalStoreId = ref(0)
            const { proxy } = getCurrentInstance()
            const L = proxy.$getCurLanguage()
            const chatMemberList = reactive({//聊天会员列表
                list: []
            })




            // const store = useStore()
            const isStoreAdmin = localStorage.getItem('isStoreAdmin')
            const isSuper = localStorage.getItem('isSuper')
            const searchMember = ref('')
            const dateRange = ref('')//日期起止事件
            const msgFilter = ref('')//消息内容
            const firstLoad = ref(true)
            const cacIdx = ref(0)
            const minMsgId = ref('');//当前消息的最小id
            const clientHeight = ref(0)
            const vendorName = ref('')
            const vendorId = ref(0)
            const curStoreId = ref(null)
            const identity = localStorage.getItem('identity')
            const adminVenList = ref([])
            const vendorChoose = ref('')
            const storeChoose = ref('')
            const showUnderAdmin = ref(false)

            const adminVenDisable = computed(() => {
                return identity == 'admin' && cacIdx.value == 1 && !storeChoose.value
            })


            //点击事件--将memberId赋值给全局变量，获取聊天记录
            const chatInit = (item) => {
                let window_instance = proxy.$refs.chatWindow

                let { memberId, storeId, vendorId } = item
                window_instance.clearFilter()
                window_instance.setId(memberId, vendorId, storeId)
                GlobalMemberId.value = memberId
                GlobalStoreId.value = storeId
                getChatLogList()

            }







            //聊天消息已读
            const msgReadDone = (memberId) => {
                let params = {
                    memberId
                }
                proxy.$post(`v3/helpdesk/${identity}/chat/markRead`, params).then()
            }
            const hasMore = reactive({
                chatList: false,
            })


            const loadState = ref('')
            const startTime = ref('')
            const endTime = ref('')


            const adminStoreList = ref([])
            const sellerStoreList = ref([])
            const didSearch = ref(false)
            const firstLoad1 = ref(true)

            const adminParam = reactive({
                plaform: {
                    storeId: 0,
                    vendorId: 0,
                    memberId: 0
                },

                seller: {
                    storeId: 0,
                    vendorId: 0,
                    memberId: 0
                }
            })



            //获取聊天会员列表
            const getMemberChatList = () => {
                const params = {
                    pageSize: 20
                };

                const window_instance = proxy.$refs.chatWindow

                if (minMsgId.value) {
                    params.msgId = minMsgId.value;
                }

                if (vendorId.value) {
                    params.vendorId = vendorId.value
                }

                if (vendorChoose.value) {
                    params.vendorId = vendorChoose.value
                }


                if (curStoreId.value != null) {
                    params.storeId = curStoreId.value
                }
                proxy.$get(`v3/helpdesk/${identity}/chat/list`, params).then(res => {
                    if (res.state == 200) {
                        if (minMsgId.value) {
                            chatMemberList.list = chatMemberList.list.concat(res.data);
                        } else {
                            chatMemberList.list = res.data
                        }
                        if (chatMemberList.list.length) {
                            minMsgId.value = chatMemberList.list[chatMemberList.list.length - 1].msgId

                        }
                        hasMore.chatList = res.data.length < 10 ? false : true;
                        if (hasMore.chatList) {
                            loadState.value = 'allow_loading_more';
                        } else {
                            loadState.value = 'no_more_data';
                        }

                        if (chatMemberList.list.length > 0) {
                            GlobalMemberId.value = chatMemberList.list[0].memberId
                            GlobalStoreId.value = chatMemberList.list[0].storeId
                            minMsgId.value = chatMemberList.list[chatMemberList.list.length - 1].msgId
                            if (firstLoad.value) {
                                window_instance.setId(GlobalMemberId.value, vendorId.value || vendorChoose.value, GlobalStoreId.value)
                                getChatLogList()
                            }

                        }
                    } else {
                        chatMemberList.list = []
                    }
                })
            }
            //获取聊天记录列表

            const getChatLogList = () => {
                let window_instance = proxy.$refs.chatWindow
                window_instance.setCurrent(1)
                window_instance.getList()
            }



            //查询
            const query = () => {

                const window_instance = proxy.$refs.chatWindow
                // if (!msgFilter.value && !dateRange.value.length) {
                //     return
                // }

                if (identity == 'admin') {
                    if (!GlobalMemberId.value) {
                        ElMessage('请先选择会员')
                        return
                    }


                    if (!vendorChoose.value) {
                        ElMessage('请先选择客服')
                        return
                    }
                }

                window_instance.setQuery(msgFilter.value, dateRange.value)

                getChatLogList()
            }

            watch(dateRange, (nv, ov) => {
                if (!nv) {
                    const window_instance = proxy.$refs.chatWindow
                    window_instance.setQuery(msgFilter.value, dateRange.value)
                    window_instance.getList()

                }
            })

            // 向下滚动至底部加载数据
            const loadlList = () => {
                console.log(hasMore.chatList, 'chatList')
                if (hasMore.chatList) {

                    firstLoad.value = false
                    getMemberChatList()
                }
            }

            //seller下的获取客服列表
            const getSellerList = () => {
                proxy.$get(`v3/${identity}/${identity}/vendor/keFuList`).then(res => {
                    if (res.state == 200) {
                        let res1 = res.data.filter(i => i.isAllowLogin == 1)
                        sellerStoreList.value = res1.sort((a, b) => { return b.isStoreAdmin - a.isStoreAdmin })
                        vendorId.value = sellerStoreList.value[cacIdx.value].vendorId
                        getMemberChatList()
                    }
                })
            }

            //admin下获取店铺列表
            const getAdminStoreList = () => {
                proxy.$get(`v3/helpdesk/admin/chat/storeList`).then(res => {
                    if (res.state == 200) {
                        adminStoreList.value = res.data.filter(i => i.storeId != 0)

                        if (cacIdx.value == 1) {
                            storeChoose.value = adminParam.seller.storeId
                        }

                        if (cacIdx.value == 0) {
                            adminVendorList()
                        }


                    }
                })
            }

            //admin的店铺列表下获取客服列表
            const adminVendorList = () => {
                let param = {}

                if (cacIdx.value == 0) {
                    param.storeId = adminParam.plaform.storeId
                } else {
                    param.storeId = adminParam.seller.storeId
                }

                proxy.$get(`v3/helpdesk/admin/chat/vendorList`, param).then(res => {
                    if (res.state == 200) {
                        adminVenList.value = res.data
                        let { seller, plaform } = adminParam

                        switch (cacIdx.value) {
                            case 0: {
                                let index = adminVenList.value.findIndex(i => i.vendorId == plaform.vendorId)

                                if (index > -1) {
                                    vendorChoose.value = res.data[index].vendorId
                                } else {
                                    vendorChoose.value = res.data[0].vendorId
                                    adminParam.plaform.vendorId = vendorChoose.value

                                }

                                break
                            }

                            case 1: {
                                let index = adminVenList.value.findIndex(i => i.vendorId == seller.vendorId)
                                if (index > -1) {
                                    vendorChoose.value = res.data[index].vendorId
                                }
                                else {
                                    vendorChoose.value = res.data[0].vendorId
                                    adminParam.seller.vendorId = vendorChoose.value

                                }


                                break
                            }
                        }

                        minMsgId.value = 0
                        getMemberChatList()

                    }
                })

            }

            const adminVenChange = () => {

                if (cacIdx.value == 0) {
                    adminParam.plaform.vendorId = vendorChoose.value
                } else {
                    adminParam.seller.vendorId = vendorChoose.value
                }


                minMsgId.value = 0
                getMemberChatList()
            }

            const adminStoChange = () => {
                adminParam.seller.storeId = storeChoose.value
                curStoreId.value = storeChoose.value
                minMsgId.value = 0
                GlobalMemberId.value = ''
                adminVendorList()
            }

            const clearContent = (type) => {
                const window_instance = proxy.$refs.chatWindow
                if (type == 'content') {
                    window_instance.clearMsgContent()
                } else {
                    window_instance.clearDate()

                }
                getChatLogList()
            }

            const searchMemberList = () => {

                if (!searchMember.value) {
                    didSearch.value = false
                    return
                }

                didSearch.value = true

                let params = {
                    memberName: searchMember.value,
                    storeId: curStoreId.value,
                    pageSize: 20
                }

                if (vendorChoose.value) {
                    params.vendorId = vendorChoose.value
                }

                if (vendorId.value) {
                    params.vendorId = vendorId.value
                }

                proxy.$get(`v3/helpdesk/${identity}/chat/list`, params).then(res => {
                    if (res.state == 200) {
                        chatMemberList.list = res.data
                        if (chatMemberList.list.length > 0) {
                            GlobalMemberId.value = chatMemberList.list[0].memberId
                            GlobalStoreId.value = chatMemberList.list[0].storeId
                            minMsgId.value = chatMemberList.list[chatMemberList.list.length - 1].msgId
                            getChatLogList()
                        } else {
                            minMsgId.value = 0
                        }
                    }
                })
            }

            const clearInput = () => {
                searchMember.value = ''
                if (didSearch.value) {
                    minMsgId.value = 0
                    getMemberChatList()
                }
            }

            const disabledDate = (time) => {
                return time.getTime() > Date.now();
            }

            const change = (index) => {
                cacIdx.value = index
                if (index == 1 && identity == 'admin') {
                    showUnderAdmin.value = true
                } else {
                    showUnderAdmin.value = false
                }
            }


            const changeUnderSeller = (index, item) => {
                cacIdx.value = index
                vendorId.value = item.vendorId
                minMsgId.value = 0
                getMemberChatList()
            }

            const changeUnderAdmin = (index, item) => {

                const window_instance = proxy.$refs.chatWindow

                cacIdx.value = index
                if (cacIdx.value == 0) {
                    showUnderAdmin.value = false
                    curStoreId.value = adminParam.plaform.storeId
                    adminVendorList()
                } else {
                    storeChoose.value = adminParam.seller.storeId || ''
                    vendorChoose.value = adminParam.seller.vendorId || ''
                    showUnderAdmin.value = true

                    if (storeChoose.value && vendorChoose.value) {
                        curStoreId.value = storeChoose.value
                        minMsgId.value = 0
                        adminVendorList()

                    } else {
                        minMsgId.value = 0
                        chatMemberList.list = []
                        window_instance.clearMsgList()
                    }
                }

            }


            onMounted(() => {
                if (identity == 'seller') {
                    if (isStoreAdmin == 1) {
                        getSellerList()
                    } else {
                        getMemberChatList()
                    }
                } else {
                    curStoreId.value = 0
                    getAdminStoreList()
                }
                clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight

            })

            return {
                chatMemberList,
                getChatLogList,
                dateRange,
                msgFilter,
                query,
                chatInit,
                msgReadDone,
                cacIdx,
                loadlList,
                loadState,
                adminStoreList,
                vendorName,
                GlobalMemberId,
                GlobalStoreId,
                clearContent,
                searchMemberList,
                searchMember,
                clearInput,
                isStoreAdmin,
                disabledDate,
                didSearch,
                L,
                change,
                vendorChoose,
                adminVenList,
                identity,
                adminVenChange,
                storeChoose,
                showUnderAdmin,
                adminStoChange,
                isSuper,
                sellerStoreList,
                changeUnderAdmin,
                clientHeight,
                adminVenDisable,
                changeUnderSeller
            }
        }
    }
</script>

<style lang="scss">
    .wind_chat {
        width: 100%;
    }

    .sld_chat_history {

        .top_header {
            width: 100%;
            height: 59px;
            padding: 15px 0px;
            border-bottom: 1px solid #F6F6F6;

            .header_title {
                padding-left: 10px;
                border-left: 4px solid #0563FF;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
            }
        }


        .main_acc_top {
            height: 44px;
            border-bottom: 1px solid #F6F6F6;
            display: flex;
            padding-top: 9px;

            .acc_top_item {
                min-width: 129px;
                text-align: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                align-self: flex-end;
                color: #666666;
                padding-bottom: 11px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;

                &.csOn {
                    border-bottom: 2px solid #198AFF;
                    color: #198AFF;
                }
            }
        }

        .middle_bar {
            width: 100%;
            height: 69px;
            display: flex;
            align-items: center;

            .acc_name {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                margin-right: 100px;
                height: 30px;
                display: flex;
                align-items: center;

                .el-input__inner {
                    height: 30px;
                }

                .el-input__icon {
                    line-height: 30px;
                }

                span {
                    display: inline-block;
                    max-width: 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .chat_content {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;

                input {
                    outline: none;
                    width: 246px;
                    height: 30px;
                    background: #FFFFFF;
                    border: 1px solid #F6F6F6;
                    border-radius: 3px;
                }

                span {
                    display: inline-block;
                    width: 90px;
                }

                ::-webkit-input-placeholder {
                    position: relative;
                    left: 7px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }

                /* 使用webkit内核的浏览器 */
                :-moz-placeholder {
                    position: relative;
                    left: 7px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }

                /* Firefox版本4-18 */
                ::-moz-placeholder {
                    position: relative;
                    left: 7px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }

                /* Firefox版本19+ */
                :-ms-input-placeholder {
                    position: relative;
                    left: 7px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }

                /* IE浏览器 */
            }

            .chat_date {
                display: flex;
                align-items: center;
                margin-left: 50px;

            }

            .query {
                width: 74px;
                height: 30px;
                background: #0E6FD7;
                border-radius: 3px;
                border: none;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                margin-left: 50px;
                cursor: pointer;
            }
        }

        .chat_con {
            display: flex;
            border: 1px solid #F6F6F6;

            .search {
                height: 60px;
                padding-left: 20px;
                display: flex;
                align-items: center;
                position: relative;
                border-bottom: 1px solid #f3f3f3;

                .search_wrap {
                    display: flex;
                    align-items: center;
                    width: 223px;
                    height: 28px;
                    border-top-left-radius: 13px;
                    border-bottom-left-radius: 13px;
                    border: 1px solid #F3F3F3;
                    border-right: none;
                    border-top-right-radius: 13px;
                    border-bottom-right-radius: 13px;
                }

                i {
                    margin-left: 5px;
                    font-size: 15px;
                    color: #999999;
                    cursor: pointer;

                    &.el-icon-close {
                        font-size: 17px;
                        position: absolute;
                        right: 77px;
                        top: 50%;
                        margin-top: -9px;
                    }
                }

                input {
                    width: 150px;
                    height: 26px;
                    outline: none;
                    border: none;
                    margin-left: 5px;
                }

                input::placeholder {

                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }

                ::-webkit-input-placeholder {

                    margin-left: 20px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }

                /* 使用webkit内核的浏览器 */
                :-moz-placeholder {

                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }

                /* Firefox版本19+ */
                :-ms-input-placeholder {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }

                /* IE浏览器 */

                .button {
                    width: 60px;
                    height: 28px;
                    background: #0E6FD6;
                    border-top-right-radius: 13px;
                    border-bottom-right-radius: 13px;
                    border: none;
                    color: #fff;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }


            .left_list {
                width: 260px;

                .left_list_item {
                    width: 100%;
                    height: 52px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;

                    &>img {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        margin-left: 20px;

                    }


                    p {
                        margin-left: 10px;
                    }

                    .item_icon {
                        position: absolute;
                        right: 20px;
                        display: flex;
                        align-items: center;

                        &>img {
                            margin-left: 10px;
                            display: none;
                        }

                        i {
                            display: inline-block;
                            border-radius: 8px;
                            color: #fff;
                            font-style: normal;
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            background-color: $colorMain;
                            padding: 2px 3px;
                            transform: scale(0.9)
                        }
                    }

                    &:hover {
                        background: #F5F5F5;

                        .item_icon {
                            img {
                                display: block;
                            }
                        }
                    }
                }
            }




        }
    }

    .el-range-editor.el-input__inner {
        height: 30px;
    }

    .el-date-editor .el-range__icon {
        line-height: 25px;
    }

    .el-date-editor .el-range-separator {
        line-height: 25px;
    }

    .el-range-editor.is-active,
    .el-range-editor.is-active:hover {
        border-color: #F6F6F6;
    }

    .el-input__inner {
        border-color: #F6F6F6;
    }

    .el-scrollbar .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    .el-date-editor .el-range__close-icon {
        line-height: 25px;
    }

    .el-input__suffix-inner {
        line-height: 30px;
        display: block !important;
    }

    .empty_data_left {
        height: 300px;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 80px;
        }

        p {
            margin-top: 15px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #D5D5D5;
        }
    }

    .goods_model {
        display: flex;

        &>img {
            width: 84px;
            height: 84px;
            background: #707070;
            border-radius: 6px;
        }

        .goods_info {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 450px;

            .goods_info_title {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                padding-right: 10px;
            }

            .goods_info_bottom {
                display: flex;
                justify-content: space-between;

                span:first-child {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #e2231a;
                }

                span:nth-child(2) {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #e2231a;
                }
            }
        }
    }

    #el-scrollbar_top .el-scrollbar__wrap .el-scrollbar__view {
        white-space: nowrap;
        display: flex;
    }
</style>